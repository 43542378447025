import { NextPage } from "next";
import React, { ReactNode } from "react";
import Head from "next/head";
import useMediaQuery from "@mui/material/useMediaQuery";

import { baseS3URL, coreUrl } from "src/lib/environment";
import { useUser } from "src/hooks/useUser";
import { useProjectSearch } from "src/hooks/useProjectSearch";
import { trackEvent } from "src/lib/analytics";

import GetStartedBanner from "src/components/index/GetStartedBanner";
import SearchBar from "src/components/index/SearchBar";
import { socialMetadata } from "src/components/layout/Head";
import FeaturedProjectsCarousel from "src/components/index/FeaturedProjectsCarousel";
import BrowseAndExploreBanner from "src/components/index/BrowseAndExploreBanner";
import FireIcon from "src/components/icons/FireIcon";

const IndexHome: NextPage = () => {
  const [user] = useUser();
  const search = useProjectSearch();

  const logGettingStartedClick = () => {
    trackEvent(
      {
        category: "Activity",
        action: "Click Getting Started button",
      },
      user
    );
  };

  const logDocsClick = () => {
    trackEvent(
      {
        category: "Activity",
        action: "Docs Link Clicked: Main",
      },
      user
    );
  };

  const popularSearches = [
    "coronavirus",
    "warehouse",
    "economy",
    "road traffic",
    "cloud infastructure",
  ];

  const lg = useMediaQuery("(max-width: 1199px)");
  const sm = useMediaQuery("(max-width: 767px)");

  return (
    <>
      <Head>
        {socialMetadata({
          title: "HASH - Browse Simulations",
          description: "Build simulations in minutes not days. HASH is an open-source platform for modeling the world.",
        })}
      </Head>
      <div className="index-home-page-wrapper search-home content-min-height">
        <div className="search-home-header">
          <div className="container">
            <div className="image-and-searchbar">
              <div className="header-image index">
                <img src={baseS3URL + "/assets/hIndex.png"} />
              </div>

              <div>
                <div className="index-homepage-header">
                  {!sm && (
                    <p>
                      Welcome to{" "}
                      <span className="h-index">
                        <span>h</span>Index
                      </span>
                    </p>
                  )}
                  <h1>
                    <span className="light">Find </span>
                    models
                    <span className="light">, </span>
                    agents
                    <span className="light">, </span>
                    data
                    <span className="light"> and more</span>
                  </h1>
                </div>
                <div className="homepage-searchbar">
                  <SearchBar />
                </div>
              </div>
            </div>

            <div className="popular-searches">
              <span className="popular-searches-header">
                <span>POPULAR SEARCHES</span>
                <FireIcon />
              </span>
              <span>
                {popularSearches.map<ReactNode>((query, i) => (
                  <React.Fragment key={i}>
                    <a
                      onClick={() => search({ query, sort: "relevance" })}
                      key={query}
                    >
                      {query}
                    </a>
                    {"  "}
                  </React.Fragment>
                ))}
              </span>
            </div>
          </div>
        </div>

        <BrowseAndExploreBanner
          // no link, core.hash.ai being deprecated
          gettingStartedLink={undefined}
        />
        <FeaturedProjectsCarousel />
        <div className="index-home-banners">
          <GetStartedBanner
            link={"https://github.com/hashintel/labs/tree/main/apps/sim-core"}
            logClick={logGettingStartedClick}
            logDocsClick={logDocsClick}
          />
        </div>
      </div>
    </>
  );
};

export default IndexHome;
